import React from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import './subpage.css'

export default ({ data }) => {
  console.log(data)
  return (
    <Layout>

      <Helmet>
        <title>AlphaDex | The Best Amazon Podcasts</title>
        <meta name="description" content="AlphaDex is your go to spot for finding the best tools and resources for selling on amazon. These are the best podcasts to help you learn the ins and outs of Amazon." />
      </Helmet>

      <div>
        <Link to={'/'}>Back</Link>
        <h1>Alphadex preferred podcasts</h1>
        <table className="subpage-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Frequency</th>
            </tr>
          </thead>
          <tbody>
            {data.allPodcastsJson.edges[0].node.podcasts.map((podcast, index) => (
              <tr key={index}>
                <td><a href={podcast.link + "?ref=thealphadex.com"} target="_blank" rel="noopener noreferrer">{podcast.name}</a></td>
                <td>{podcast.description}</td>
                <td>
                  {podcast.frequency != null &&
                    <strong className="mobile-show">Frequency: </strong>
                  }{podcast.frequency}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allPodcastsJson {
      edges {
        node {
          podcasts{
            name
            link
            frequency
            description
          }
        }
      }
    }
  }
`